

import styled from 'styled-components'

const CategoryWelcomeStyle = styled.div`
width : 90%;
color : white ;
margin : auto;
padding-top : 7rem;

.all-btn{
  float : right ;
  font-size : 1.2rem;
}

.button-all-wrapper{
  width : 97.5%;

  button{
    float: right;
    margin-bottom: 45px;
    margin-top: 30px;
    font-size: 20px;
    border : solid 1px white;
    font-weight:200;
    width:200px;
  }

}

.button-coll{
  float : right;
}

.custom-slider{
    
}

@media only screen and (min-width: 3400px) {
  padding-top : 20rem;
  padding-bottom:7rem
}

@media screen and (min-width: 2200px) and (max-width: 3400px){
    padding-top : 14rem;
    padding-bottom:7rem;
}

@media screen and (min-width : 1980px) {
  .button-all-wrapper{
    button{
      font-size : 1vw;
      padding : 30px;
      font-weight:200;
      border : solid 1px white;
      width:15%;
    }
  }
}

@media only screen and (max-width: 1650px) {
  padding-top : 4rem;
}

@media only screen and (max-width: 1350px) {
  padding-top : 2rem;
}

@media only screen and (max-width: 1050px) {
  padding-top : 1rem;
}

@media only screen and (max-width: 768px) {
  height:100%;
  padding-top : 0rem;
}
`

export default CategoryWelcomeStyle;