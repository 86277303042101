import styled from "styled-components";
import FlexBox from "../FlexBox";

const StyledSubcategories = styled(FlexBox)`
    margin-bottom : 8rem;
    gap : 3rem;
    margin-top : 3rem;

    img {
        height : 29.5vw;
        width : 40vw;
        border-radius : 17px;
    }

    .btn{
        position: absolute;
        bottom: 0;
        right: 0;
        margin: 10px;
        padding: 10px 80px;
        color: #fff;
        border: solid 1px white;
        border-radius: 5px;
       z-index: 1 ;
       background: rgba(0, 0, 0, 0.7);
    }

    @media only screen and (min-width : 1980px){
        font-size : 1vw;
        margin-bottom : 10rem;
    }

    @media only screen and (max-width : 768px){
        flex-direction : column;
        width : 82%;
        margin : auto;
        margin-bottom : 5rem;
        margin-top:3rem;
        
        .btn{
            padding: 1px 20px;
        }

        img{
            height : 54vw;
            width:100%;
        }
        
    }

`
export default StyledSubcategories