import styled from "styled-components";
import Box from "../Box";
import FlexBox from "../FlexBox";

const ServicesStyle = styled(FlexBox)`
    width : 100%;
    height : 17vw;
    color : white;
    background-color : black;
    

    .services-title{
        font-size : 20px;
        font-weigh : bold;
    }

    

    img {
        height : 95px;
        width : 95px;
    }

    @media only screen and (min-width : 1980px){
        .services-title {
            font-size : 1vw;

        }

        .services-desc{
            font-size : 0.8vw;
            
        }

        .innerService img {
            height:5vw;
            width:5vw;
        }
    }

    @media only screen and (max-width : 900px){
        height : 771px;
        flex-direction : column ;
        margin-top : 5rem;
        align-items:center;

        .innerService {
            flex-direction: column;
            align-items: center;
        }
        .innerService > div {
            align-items : center;
        }
    }
   
`

export default ServicesStyle