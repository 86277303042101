import React from 'react'
import Box from '../Box'
import StyledSubcategories from '../home-1/SubcategoriesStyle'
import BgImg from './subcat-bg.jpg'
import { useHomeContext } from "../../contexts/app/HomeContext.tsx";
import { Link } from '@inertiajs/react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';


function renderBoxes(sectionData) {

    const res = [];
    let i = 0;
    for (i = 0; i < sectionData.data.length; i += sectionData.data.length) {

        const chunk = sectionData.data.slice(i, 2);
        res.push(chunk);
    }

    if (sectionData.data.length % 2) {
        const chunk = sectionData.data.slice(sectionData.data.length - 1, i);
        res.push(chunk);
    }

    return res;


}

const Boxes2PerRow = (props) => {
    const { relatedData } = useHomeContext();
    let dataSection = renderBoxes(props.props)
    return (


        <>

            {dataSection.map((data, index0) =>
                <StyledSubcategories key={"boxespertwostyled" + index0} justifyContent={'center'}>
                    {data.map((section, index) =>



                        <Box key={"boxespertwo" + index} style={{ position: 'relative' }}>
                            <Link href={relatedData[section.sectionData.id] ? relatedData[section.sectionData.id][0].slug : "#"}>
                                <LazyLoadImage
                                    visibleByDefault={true}
                                    delayTime={100}
                                    alt={section.sectionData.name}
                                    height='100%'
                                    src={'/cache/homepage-box' + section.image} // use normal <img> attributes as props
                                    width='100%'
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = "themes/default/assets/images/category2/boxer.webp";
                                    }}
                                />
                            </Link>
                            {/*<img src={'/cache/homepage-box'+section.image}  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src="themes/default/assets/images/category2/boxer.webp";
                }}/>*/}
                            <Link href={relatedData[section.sectionData.id] ? relatedData[section.sectionData.id][0].slug : "#"} className='btn'>
                                {section.sectionData.name} <NavigateNextIcon />
                            </Link>
                        </Box>


                    )}
                </StyledSubcategories>
            )}



        </>
    )
}

export default Boxes2PerRow