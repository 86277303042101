import Box from "../Box";
import Card from "../Card";
import Typography, { H3, Small, Span } from "../Typography";
import NextImage from "next/image";
import { Link } from '@inertiajs/react'
import React, { useEffect } from "react";
import { useHomeContext } from "../../contexts/app/HomeContext.tsx";
import styled from "styled-components";
import bgImage from "./images/bg-category.png"
import Carousel from "../carousel/Carousel";
import { position } from "styled-system";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import StyledCategory1 from "./Category1Style";
import { StyledImgContainer } from "./Category1Style";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';




export interface FashionCard1Props { }

const Category1: React.FC<FashionCard1Props> = ({ section, sectionName }) => {

  const { relatedData } = useHomeContext();

  let data = section.sectionData.id

  return (

    <StyledCategory1

      p="2.5rem"
      pb={0}
      elevation={0}
      boxShadow={0}
      height="100%"
      bg={'#212121'}

    >
      <Link href={relatedData[data] ? relatedData[data][0].slug : "#"} >

        <StyledImgContainer
          backgroundImage={String(section.background_image)}
        />

        <LazyLoadImage
          visibleByDefault={true}
          delayTime={100}
          alt={sectionName}
          //height='100%'
          src={'/cache/homepage-box' + section.image} // use normal <img> attributes as props
          width='90%'
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = "themes/default/assets/images/category1/model.webp";
          }}
        />

      </Link>
      <Box className='category-button'>
        <Link href={relatedData[data] ? relatedData[data][0].slug : "#"}>
          <H3
            fontWeight="500"
          >
            {sectionName} <NavigateNextIcon/>
          </H3>
        </Link>
      </Box>

    </StyledCategory1>

  );
};

export default Category1;
