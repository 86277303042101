import styled from "styled-components";
import Card from "../Card";

const StyledCategory1 = styled(Card)`

  a{
    position : relative;
    display: inline-block; 
    width: 100%;
    height: auto;
  }

  img {
    position : absolute;
    bottom : 0;
    right : 0;
  }

  .category-button{
    box-shadow: none;
    cursor: unset;
    position: relative;
    z-index: 1;
    color: white;
    text-align: center;
    background-color: rgb(0, 0, 0);
    padding: 0.3rem;
    width: 50%;
    margin: auto;
    border-radius: 5px;
    bottom: 20px;
  }

  @media only screen and (min-width : 1980px){
    .category-button h3 {
        font-size : 1vw;
    }
  }

  @media only screen and (max-width : 768px){
    padding : 1rem;
    img {
      width:70%;
    }

    .category-button{
      width: 70%;
    }
    
  }

  @media only screen and (min-width : 580px){

  img{
    
    transition : 0.5s ease;
    z-index:1;
    margin-left : 2vw;

    :hover{
     transition : 0.5s ease;
     transform : scale(1.1)  translate(-20px, -28px);
    
  }

`

export const StyledImgContainer = styled.div`
    height : 25vw; 
    background-size : cover;
    border-radius : 20px;
    position:relative;
    background-repeat:no-repeat;
    background-image : url(/cache/homepage-box${props => props.backgroundImage});
    

    ::after{
      content: "";
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background: linear-gradient(to top, rgba(0,0,0,0.2), rgba(0,0,0,05));
     border-radius:19px;
        }

    @media only screen and (max-width : 768px){
            height : 80vw;
        }

`

export default StyledCategory1