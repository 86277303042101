
import React from 'react'
import FlexBox from '../FlexBox'
import { Small, Span } from '../Typography'
import ServicesStyle from './ServicesStyle'
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Services = (props) => {


  return (
    <ServicesStyle justifyContent="space-around" alignItems="center" className='service-box'>

{props.props.data.map((service,index) => (
      <FlexBox key={"service"+index} className='innerService' alignItems={'center'}>
        {/*<img src={'/cache/homepage-box'+service.image} onError={({ currentTarget }) => {
    currentTarget.onerror = null; // prevents looping
    currentTarget.src="themes/default/assets/images/services/service.webp";
    }}/>*/}

      <LazyLoadImage
            visibleByDefault={true}
            alt={service.sectionData.secondRow}
            delayTime={100}
            height='100%'
            src={'/cache/homepage-box'+service.image} // use normal <img> attributes as props
            width='100%' 
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src="themes/default/assets/images/services/service.webp";
            }}
      />

        <FlexBox flexDirection={'column'} >
          <Span className='services-title'>{service.sectionData.firstRow}</Span>
          <Small className='services-desc'>{service.sectionData.secondRow}</Small>
        </FlexBox>
      </FlexBox>
))}
    </ServicesStyle>
  )
}



export default Services
